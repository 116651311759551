import { COUPON_INTERFACE } from "..";
import Toogle from "../../../../components/common/Toogle";
import React, { memo, useCallback, useState } from "react";
import moment from "moment";
import { UpdateStatusService } from "../../../../services/coupon/updateStatus.service";

interface TABLEINTERFACE {
  coupon: any;
  changeStatusOfDiscount?: any;
  getAllCoupons?: any;
}

const Table: React.FC<TABLEINTERFACE> = ({
  coupon,
  changeStatusOfDiscount,
  getAllCoupons,
}) => {
  const [query, setQuery] = useState<string>("");
  const [isFolderAccessOpen, setIsFolderAccessOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const accessHandler = (userId: string) => {
    setSelectedUserId(userId);
    setIsFolderAccessOpen(true);
  };

  //DEBOUNCE
  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  // Debounced search function memoized with useCallback
  const debounceSearch = useCallback(
    debounce((query: string) => {
      // Make API call with the search query
      getAllCoupons(query);
    }, 800),
    []
  );

  // onChange Handler
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <main>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-column flex-row-reverse sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              name="search-query"
              value={query}
              onChange={onChangeHandler}
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for items"
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Coupon Code
              </th>
              <th scope="col" className="px-6 py-3">
                Discount(in %)
              </th>
              <th scope="col" className="px-6 py-3">
                Valid Till
              </th>
              <th scope="col" className="px-6 py-3">
                Associated With
              </th>
              <th scope="col" className="px-6 py-3">
                Active
              </th>
            </tr>
          </thead>
          <tbody>
            {coupon?.map((item: COUPON_INTERFACE) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item?.couponCode}
                </th>
                <td className="px-6 py-4">{item?.discount}</td>
                <td className="px-6 py-4">
                  {moment(item?.validTill).format("MM-DD-YYYY")}
                </td>
                <td className="px-6 py-4 cursor-pointer hover:text-customBlue">
                  Course Access
                </td>
                <td className="px-6 py-4">
                  <Toogle
                    isActive={item?.isActive}
                    id={item?._id}
                    changeStatusOfDiscount={changeStatusOfDiscount}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default memo(Table);
